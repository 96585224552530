<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="dataComputed.client_id"
              dense
              outlined
              :label="t('projects.client')"
              :items="clientsOptions"
              item-text="name"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
              @change="(e) => {
                fetchProjectsByClient(e)
                dataComputed.project_id = null
              }"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="dataComputed.project_id"
              dense
              outlined
              :label="t('projects.project')"
              :items="projectsOptions"
              item-text="name"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :disabled="!dataComputed.client_id"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.mix_type_id"
              dense
              outlined
              :label="t('projects.mix_type')"
              :items="mixsOptions"
              item-text="name"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.vehicle_id"
              dense
              outlined
              :label="t('projects.vehicle')"
              :items="vehiclesOptions"
              :item-text="(item) => `${item.id_number} - ${item.model} ${item.color}`"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.driver_id"
              dense
              outlined
              :label="t('projects.driver')"
              :items="driversOptions"
              item-text="fullname"
              item-value="id"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-menu
              v-model="modal"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :open-on-click="option!==2"
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="inputDate"
                  ref="inputDate"
                  v-model="dataComputed.shipping_date"
                  :label="t('transactions.date')"
                  color="primary"
                  outlined
                  clearable
                  dense
                  persistent-hint
                  :append-icon="icons.mdiCalendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="() => {
                    date = new Date().toISOString().substr(0, 10)
                    $refs.inputDate.blur()
                  }"
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="dataComputed.shipping_date"
                no-title
                color="secondary"
                @input="modal = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            :md="option !== 1 ? 3 : 4"
          >
            <v-text-field
              v-model.number="dataComputed.shipping_weight"
              :label="t('projects.quantity_ordered')"
              dense
              outlined
              :readonly="option===2"
              :rules="[]"
              @keypress="onlyNumberDot"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="option !== 1"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model.number="dataComputed.amount"
              :label="t('projects.quantity_shipped')"
              dense
              outlined
              readonly
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            :md="option !== 1 ? 3 : 4"
          >
            <v-textarea
              v-model="dataComputed.note"
              label="Description"
              outlined
              rows="2"
              :readonly="option===2"
              :rules="[required]"
            ></v-textarea>
          </v-col>

          <v-col
            v-if="dataComputed.status_str"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model.number="dataComputed.status_str"
              :label="t('status.status')"
              dense
              outlined
              readonly
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="dataComputed.status === 'completed'"
            cols="12"
          >
            <v-data-table
              :headers="tableColumns"
              :items="shippingHeaps"
              item-key="id"
              :items-per-page="-1"
              :loading-text="t('table.loading_text')"
              :no-data-text="t('table.no_data')"
              hide-default-footer
              :header-props="{sortByText: t('table.sort_by')}"
            />
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMenuDown, mdiCalendar } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { onlyNumberDot } from '@/@core/utils/functionsHelpers'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import {
  getClientsActive, getProductsActiveClient, getMixTypesActive, getVehiclesActive, getDriversActive, getShippingHeaps,
} from '@api'
import moment from 'moment'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const modal = ref(false)
    const dataAcount = ref({})
    const initialData = {
      name: null,
      note: null,
      status: null,
    }
    const clientsOptions = ref([])
    const projectsOptions = ref([])
    const mixsOptions = ref([])
    const vehiclesOptions = ref([])
    const driversOptions = ref([])

    const shippingHeaps = ref([])
    const tableColumns = computed(() => [
      {
        text: t('projects.date').toUpperCase(), value: 'register_date', show: true, sortable: false,
      },
      {
        text: t('projects.type').toUpperCase(), value: 'register_type', show: true, sortable: false,
      },
      {
        text: `${t('projects.weight').toUpperCase()} (${t('projects.tons').toUpperCase()})`, value: 'heap_weight', show: true, sortable: false,
      },
    ])

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const fetchShippingHeaps = async () => {
      const response6 = await getShippingHeaps(dataComputed.value.id)
      if (response6.ok) {
        shippingHeaps.value = response6.data.map(item => ({ ...item, register_date: moment(item.register_date).format('DD/MM/YYYY HH:mm:ss') }))
      } else {
        shippingHeaps.value = []
      }
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        name: null,
        note: null,
        status: null,
      })
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const fetchProjectsByClient = async client => {
      if (dataComputed.value.client_id) {
        const response = await getProductsActiveClient(client)
        if (response.ok) {
          projectsOptions.value = response.data
        } else {
          projectsOptions.value = []
        }
      }
    }

    onMounted(async () => {
      const response = await getClientsActive()
      if (response.ok) {
        clientsOptions.value = response.data
      } else {
        clientsOptions.value = []
      }

      const response3 = await getMixTypesActive()
      if (response3.ok) {
        mixsOptions.value = response3.data
      } else {
        mixsOptions.value = []
      }

      const response4 = await getVehiclesActive()
      if (response4.ok) {
        vehiclesOptions.value = response4.data
      } else {
        vehiclesOptions.value = []
      }

      const response5 = await getDriversActive()
      if (response5.ok) {
        driversOptions.value = response5.data
      } else {
        driversOptions.value = []
      }

      if (props.option !== 1) {
        await fetchProjectsByClient(dataComputed.value.client_id)
      }

      if (dataComputed.value.status === 'completed') {
        await fetchShippingHeaps()
      }
    })

    return {
      // data
      form,
      modal,
      dataAcount,
      initialData,
      clientsOptions,
      projectsOptions,
      mixsOptions,
      vehiclesOptions,
      driversOptions,
      shippingHeaps,
      tableColumns,

      // computed
      dataComputed,
      statusOptions,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      fetchProjectsByClient,

      // validations
      required,
      emailValidator,
      integerValidator,
      onlyNumberDot,

      // i18n
      t,

      icons: {
        mdiMenuDown,
        mdiCalendar,
      },
    }
  },
}
</script>
